import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { JsonContent } from "../static_content";
import tetanusJson from "../../content/tetanus.json";
import { VaccinationStatus } from "../vaccination_progress";
import { formatDate } from "../../utils/common";
import { InfoPanel } from "../info_panel";

type TetanusContentProps = {
    status: VaccinationStatus
}

export const nextDoseDue = ({ next_dose_due }: VaccinationStatus) => {

    if (next_dose_due){

        const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);
        const match = dateRegex.test(next_dose_due);

        if (match){
            const dateStr = formatDate(new Date(next_dose_due))
            return (
                <Typography variant="listTitle">Next dose due from: {dateStr}</Typography>
            )
        } else {
            return (
                <Typography variant="listTitle">Next dose due: {next_dose_due}</Typography>
            )
        }
    }
}

export const CatchUpPanel = ({ catch_up }: VaccinationStatus) => {
    if (catch_up) {
        return (
            <PageSubsection>
                <InfoPanel
                    severity="warning"
                    title="Catch Up Schedule"
                >
                    <Typography variant="bodyTextLarge">
                        You are following a catch up schedule which may change the total number of recommended doses and/or timing of recommended doses.
                    </Typography>
                </InfoPanel>
            </PageSubsection>
        );
    }

    return null
};

export const TetanusContent = ({ status }: TetanusContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {status.status_text} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    <PageSubsection>
        {nextDoseDue({...status})}
    </PageSubsection>

    <CatchUpPanel {...status} />

    <Divider />

    <JsonContent>
        {tetanusJson}
    </JsonContent>
</>)
